import { useRouter } from 'next/router';
import React from 'react';
import OrderStatusChip from '../../OrderStatus/OrderStatusСhip';

import styles from './CabinetOrders.module.scss';
import { OrdersQuery } from '../../../__generated__/graphql';
import { Button } from '../../Button/Button';

type Props = {
  orders: OrdersQuery['orders']['data'];
};

const CabinetOrders = ({ orders }: Props) => {
  const router = useRouter();
  return orders ? (
    <div className={styles.ordersContainer}>
      {orders.map((order) => (
        <div className={styles.orderCard} key={order.id}>
          <div className={styles.orderNum}>{order.num}</div>
          <OrderStatusChip status={order.status} />
          <div className={styles.orderBtn}>
            <Button
              onClick={() => {
                router.push(`/cabinet/orders/${order.id}`);
              }}
              variant='outlined-mini'
            >
              Открыть
            </Button>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <p>У Вас нет активных заказов</p>
  );
};

export default CabinetOrders;
