import { useRouter } from 'next/router';
import React from 'react';

import styles from './CabinetRequests.module.scss';
import { RequestStatusChip } from '../../RequestDetails/components/RequestStatusChip';
import { QueryQuery } from '../../../__generated__/graphql';
import { Button } from '../../Button/Button';

type Props = {
  requests: QueryQuery['requests']['data'];
};

const CabinetRequests = ({ requests }: Props) => {
  const router = useRouter();
  return (
    <div className={styles.requestsContainer}>
      {requests.map((request) => (
        <div className={styles.requestCard} key={request.id}>
          <div className={styles.requestNum}>{request.num}</div>
          <div className={styles.requestStatus}>
            <RequestStatusChip status={request.status} />
          </div>
          <div className={styles.requestBtn}>
            <Button
              onClick={() => {
                router.push(`/cabinet/requests/${request.id}`);
              }}
              variant='outlined-mini'
            >
              Открыть
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CabinetRequests;
