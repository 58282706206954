import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../../../store/hooks';
import { selectCartItemCount, addProduct, deleteProduct, changeCount } from '../../../store/cartSlice';
import { AddProductButton } from '../../AddProductButton/AddProductButton';
import { ProductItem } from '../../../__generated__/graphql';

type Props = {
  product: ProductItem;
  buttonStyle?: 'filled' | 'secondary' | 'outlined' | 'outlined-mini';
}

export function ProductCartButton({ product, buttonStyle = 'outlined-mini' }: Props) {
  const dispatch = useDispatch();
  const count = useSelector(selectCartItemCount(product.id));
  const [inputValue, setInputValue] = useState<number>(count);

  useEffect(() => {
    setInputValue(count);
  }, [count]);

  const handleChangeCount = (value: number) => {
    if (value === 0) {
      setInputValue(0);
      dispatch(deleteProduct(product.id));
      return;
    } else if (!isNaN(value) && value > 0) {
      setInputValue(value);
      if (!inputValue) {
        dispatch(addProduct(product));
      } else {
        dispatch(changeCount({ product_id: product.id, count: value }));
      }
    }
    return;
  };

  return (
    <AddProductButton
      buttonText="В корзину"
      value={inputValue}
      onChange={handleChangeCount}
      buttonStyle={buttonStyle}
    />
  );
}
