import { gql } from "../../../__generated__";

export const REQUESTS_QUERY = gql(`
  query Query($limit: Int!, $offset: Int!, $filter: RequestsListFilter) {
    requests(pagination: {limit: $limit, offset: $offset}, filter: $filter) {
      totalCount
      data {
        status
        created_at
        id
        num
        title
      }
    }
  }
`);

export const REQUEST_QUERY = gql(`
query Request($requestId: String!) {
  request(id: $requestId) {
    id
    num
    status
    address
    comment
    title
    city {
      id
      name
      region {
        name
      }
    }
    requestItems {
      id
      title
      amount
      measure
      proposals {
        id
        price
        amount
        supply_days
        requestItem {
          id
          amount
        }
        productItem {
          id
          title
          price
          amount
          supply_days
          store {
            id
            city {
              name
            }
          }
        }
      }
    }
    orderItems {
      id
      amount
      price
      status
      supply_days
      store_amount
      product {
        title
        amount
        price
        id
        supply_days
        store {
          id
          city {
            id
            name
          }
        }
      }
      request_item {
        id
      }
      request_proposal_item {
        id
        amount
        price
        supply_days
      }
      order_merchant {
        order {
          id
          num
        }
      }
    }
    orders {
      id
      status
      num
      summ
      delivery_summ
    }
  }
}
`);

export const REQUEST_ADD_MUTATION = gql(`
  mutation PlaceRequest($data: RequestPayload!) {
    placeRequest(data: $data) {
      id
    }
  }
`);

export const REQUEST_ORDER_DRAFT_MUTATION = gql(`
  mutation RequestOrderDraft($items: [RequestOrderItemPayload!]!, $requestId: String!, $companyId: Int) {
    requestOrderDraft(items: $items, requestId: $requestId, companyId: $companyId) {
      id
      company {
        id
        inn
        name
        address
      }
    }
  }
`);

export const REQUEST_OPEN_MUTATION = gql(`
  mutation RequestOpen($requestOpenId: String!) {
    requestOpen(id: $requestOpenId)
  }
`);

export const REQUEST_DELETE_MUTATION = gql(`
  mutation RequestDelete($requestDeleteId: String!) {
    requestDelete(id: $requestDeleteId)
  }
`);

export const REQUEST_CLOSE_MUTATION = gql(`
  mutation RequestClose($requestCloseId: String!) {
    requestClose(id: $requestCloseId)
  }
`);

export const REQUEST_ORDER_MUTATION = gql(`
  mutation RequestOrder($requestId: String!) {
    requestOrder(requestId: $requestId) {
      id
    }
  }
`);

export const ASK_HELP_REQUEST = gql(`
  query HelpMailRequest($requestId: String!, $message: String!) {
    helpRequestMail(requestId: $requestId, message: $message)
  }
`);
