import { useState, useEffect } from 'react';
// import Link from 'next/link';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_PRODUCTS_QUERY } from '../../api/public/products';
import { Button, Pagination } from '../../components';
import { ProductsListTable } from './components/ProductsListTable';
import ProductListSkeleton from './components/ProductListSkeleton';
import styles from './ProductsList.module.scss';
import { OrderProduct } from '../RequestDetails/RequestDetails';
import { ProductItem } from '../../__generated__/graphql';
import { ContactManager } from '../ContactManager/ContactManager';


type Props = {
  search?: string;
  newOrderItems?: OrderProduct[];
  onAddProduct?: (prod: ProductItem) => void;
}

const PAGE_SIZE = 20;

export const ProductsSearch = (props: Props) => {
  const { search, newOrderItems, onAddProduct } = props;

  const [page, setPage] = useState(1);
  const [isContactManagerModalOpen, setIsContactManagerModalOpen] = useState(false);

  const [refetch, { loading, error, data }] = useLazyQuery(SEARCH_PRODUCTS_QUERY, {
    variables: {
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      search: String(search),
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    refetch({
      variables: {
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
        search: String(search),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search]);

  const getProductButton = (prod: ProductItem) => {
    const handleAddProduct = () => {
      if (onAddProduct) {
        onAddProduct(prod);
      }
    };
    const isProductInCard = newOrderItems ? newOrderItems.some((x) => x.product.id === prod.id) : false;
    return (
      <Button
        onClick={handleAddProduct}
        disabled={isProductInCard}
        variant='outlined-mini'
      >
        {isProductInCard ? 'В корзине' : 'Добавить'}
      </Button>
    );
  };

  const products = data ? data.products.data : [];

  if (loading) {
    return (
      <ProductListSkeleton />
    );
  }

  if (error) {
    return (
      <p>
        Ошибка получения данных.
      </p>
    );
  }

  if (products.length === 0) {
    return (
      <div className={styles.noProducts}>
        <p>Товаров не найдено.</p>
        <p>Свяжитесь с менеджером, уточните критерии для подбора и мы отправим вам лучшие предложения от поставщиков.</p>
        <Button onClick={() => setIsContactManagerModalOpen(true)} variant='outlined' type='button'>
          Связаться с менеджером
        </Button>
        <ContactManager isModalOpen={isContactManagerModalOpen} setIsModalOpen={() => setIsContactManagerModalOpen(false)} />
      </div>
    );
  }
  
  return (
    <div>
      <div className={styles.tableContainer}>
        <ProductsListTable actionBtn={onAddProduct && getProductButton} products={products} />
      </div>
      {data && data?.products.totalCount > PAGE_SIZE ? (
        <Pagination
          activePage={page}
          itemsCountPerPage={PAGE_SIZE}
          totalItemsCount={Number(data?.products.totalCount)}
          onChange={setPage}
        />
      ) : null}
    </div>
  );
};
