import { format as DFformat, getDay } from 'date-fns';
import { ru } from 'date-fns/locale';

export const format = (d: Date | number, f: string) => DFformat(d, f, { locale: ru });

export const formatDate = (d: Date | number) => {
  if (!d) return '';
  return format(d, 'dd.MM.yyyy');
}

export const formatDateNoYear = (d: Date | number) => {
  if (!d) return '';
  return format(d, 'dd.MM');
}

export const formatDateOnlyDay = (d: Date | number) => {
  if (!d) return '';
  return format(d, 'dd');
}

export const formatDateTime = (d: Date | number) => {
  if (!d) return '';
  return format(d, 'dd.MM.yyyy HH:mm');
}

export const formatDateMonth = (d: Date | number) => {
  if(!d) return '';
  return format(d, "dd MMMM yyyy");
}

export const formatWeekDay = (d: Date | number) => {
  return format(d, 'EEEE');
}

export const formatWeekDayShort = (d: Date | number) => {
  return format(d, 'EEE');
}

export const formatTime = (d: Date | number) => {
  if (!d) return '';
  return format(d, 'HH:mm');
}

export const formatMonth = (d: Date | number) => {
  if (!d) return '';
  const s = format(d, 'LLLL');
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const getDayRus = (d: Date | number) => {
  let day = getDay(new Date(d));
  switch (day) {
    case 0: return 6;
    default: return day - 1;
  }
}

