import { useQuery } from '@apollo/client';
import React from 'react';
import { ORDERS_QUERY } from '../../api/public/cabinet/orders';
import { REQUESTS_QUERY } from '../../api/public/cabinet/requests';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import CabinetOrders from './components/CabinetOrders';
import CabinetRequests from './components/CabinetRequests';
import ItemSkeleton from './components/ItemSkeleton';
import styles from './CabinetMainInfo.module.scss';
import Link from 'next/link';
import { OrderStatus, RequestStatus } from '../../__generated__/graphql';

export const CabinetMainInfo = () => {
  const { data: ordersData, error: ordersError, loading: ordersLoading } = useQuery(ORDERS_QUERY, {
    variables: {
      limit: 100,
      offset: 0,
      filter: {
        statuses: [OrderStatus.New, OrderStatus.Confirmed, OrderStatus.Delivery, OrderStatus.Payed]
      }
    },
    fetchPolicy: 'no-cache',
  });

  const { data: requestsData, error: requestsError, loading: requestsLoading } = useQuery(REQUESTS_QUERY, {
    variables: {
      limit: 100,
      offset: 0,
      filter: {
        statuses: [RequestStatus.Open, RequestStatus.Sended]
      }
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <div>
      <div className={styles.listSection}>
        <h3>Заказы</h3>
        {ordersLoading ? <ItemSkeleton /> :
        ordersError ? <ErrorAlert error={ordersError} /> :
        ordersData!.orders.data.length > 0 ? (
          <CabinetOrders orders={ordersData!.orders.data} />
        ) : (
          <p className={styles.emptyListText}>У Вас нет активных заказов.{' '}
          <Link href="/cabinet/orders/">Все заказы</Link>
          </p>
        )}
      </div>
      <div className={styles.listSection}>
        <h3>Заявки</h3>
        {requestsLoading ? <ItemSkeleton /> :
        requestsError ? <ErrorAlert error={requestsError} /> :
        requestsData!.requests.data.length > 0 ? (
          <CabinetRequests requests={requestsData!.requests.data} />
        ) : (
          <p className={styles.emptyListText}>У Вас нет активных заявок.{' '}
          <Link href="/cabinet/requests/">Все заявки</Link>
          </p>
        )}
      </div>
    </div>
  );
};

export default CabinetMainInfo;
