import { gql } from "../../../__generated__";

export const PROFILE_QUERY = gql(`
  query Profile {
    profile {
      id
      email
      phone
      personal {
        firstname
        surname
        patronymic
        position
      }
      billing {
        bik
        account
      }
    }
  }
`);

export const PROFILE_MUTATION = gql(`
  mutation ProfileUpdate($data: ProfilePayload!) {
    profileUpdate(data: $data) {
      id
    }
  }
`);

export const UPDATE_CITY_MUTATION = gql(`
  mutation CityUpdate($cityId: Int!) {
    cityUpdate(cityId: $cityId)
  }
`);